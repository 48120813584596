.Agora_App {
  position: relative;
  height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
  /* background: blue; */
}

#videos_shrink {
  position: absolute;
  height: 180px;
  width: 180px;
  top: 21%;
  left: 24%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

#videos {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
}

#cheklistSectionDiv {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

#videos_2 {
  position: relative;
  height: 80vh;
  width: 100%;
  z-index: 1;
}

.vid {
  position: relative;
  height: 100%;
  width: 100%;
  border: 1px solid #c6c6c6;
}

.video_call_btn {
  height: 40px;
  width: 70px;
  border-radius: 12px;
  position: relative;
}

.video_call_btn_recording {
  height: 40px;
  width: 120px;
  border-radius: 12px;
  position: relative;
}

.controls {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* MY CSS */
.fixed_screen {
  background: grey;
  position: absolute;
  height: 80vh;
  width: 100%;
  top: 0px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
}

/* MY CSS */
.Guest_fixed_screen {
  background: grey;
  position: relative;
  height: 80vh;
  width: 100%;
  top: 0px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
}

.floating_screen {
  /* background: black; */
  position: relative;
  height: 200px;
  width: 200px;
}

.Guest_floating_screen {
  /* background: black; */
  position: absolute;
  height: 200px;
  width: 200px;
  top: 0px;
  left: 0px;
}

.other_users {
  /* background: red; */
  position: relative;
  height: 100px;
  width: 100%;
  z-index: 10;
}

#Guest_otherusersrow_parent{
  max-height: 80vh;
  overflow-x: auto;
}
.Guest_otherusersrow {
  /* background: red; */
  position: relative;
  height: 200px;
  width: 100%;
  z-index: 10;

}


#otherusersrow {
  margin-top: 50vh;
}

@media (max-width: 576px) {
  #otherusersrow {
    margin-top: 65vh;
  }

  .floating_screen {
    /* background: black; */
    position: relative;
    height: 150px;
    width: 150px;
  }
}

/* .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.other_users:hover .overlay {
    opacity: 1;
} */
