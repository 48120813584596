.btnStyle {
  width: auto;
  align-items: center;
  display: flex;
  border-radius: 12px;
  padding: 0.375rem 1.55rem;
}

.btnStyle svg {
  width: 18px;
  height: 18px;
}

@media (max-width: 1199px) {
  .btnStyle {
    width: 50px !important;
    /* height: 40px !important; */
  }

  .btnStyle svg {
    width: 14px !important;
    height: 14px !important;
  }
}


.remote_user .agora_video_player {
  object-fit: contain !important;
}

/* .remote_user video {
  object-fit: contain !important;
} */