/* *{
    font-family: 'Roboto', sans-serif !important;
} */
.Toastify__toast--success {
  background: #f1f5f2 !important;
  font-family: "Roboto", sans-serif !important;
}

.Toastify__toast--error {
  background: #f1f5f2 !important;
  font-family: "Roboto", sans-serif !important;
}

#card_title {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#card_title_graph {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#card_smallTitle {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bg-primary-blue {
  background: #2264ab;
  color: white;
}

.btn-primary-blue {
  color: #fff;
  background-color: #2264ab;
  border-color: #2264ab;
}

/* #chatSpace button:disabled,
button[disabled] {
  color: #fff;
  background-color: #2264ab;
  border-color: #2264ab;
} */

.btn-primary-blue:hover {
  color: #fff;
  background-color: #155497;
  border-color: #155497;
}

/* .modal .close {
  color: rgb(245, 245, 245) !important;
  opacity: 1;
} */

.fontFamily {
  font-family: "Roboto", sans-serif !important;
}

.vdi_subscriptionplans {
  font-family: "Roboto", sans-serif !important;
}

#imageUserInfo {
  font-family: "Roboto", sans-serif !important;
}

.vdi_customer {
  font-family: "Roboto", sans-serif !important;
}

.vdi_customersubscription {
  font-family: "Roboto", sans-serif !important;
}

.v2_preview {
  font-family: "Roboto", sans-serif !important;
}

.vdi_usermaster {
  font-family: "Roboto", sans-serif !important;
}

.vdi_vessel {
  font-family: "Roboto", sans-serif !important;
}

.vdi_role {
  font-family: "Roboto", sans-serif !important;
}

.vdi_survey {
  font-family: "Roboto", sans-serif !important;
}

.vdi_surveylog {
  font-family: "Roboto", sans-serif !important;
}

.vdi_sop {
  font-family: "Roboto", sans-serif !important;
}

.vdi_checklist {
  font-family: "Roboto", sans-serif !important;
}

.Reset_Password {
  font-family: "Roboto", sans-serif !important;
}

.MailErrors {
  font-family: "Roboto", sans-serif !important;
}

.AccessErrors {
  font-family: "Roboto", sans-serif !important;
}

#vedam_signin {
  font-family: "Roboto", sans-serif !important;
}

.checklistSectionItems {
  font-family: "Roboto", sans-serif !important;
}

.chat_room {
  font-family: "Roboto", sans-serif !important;
}

.modal {
  font-family: "Roboto", sans-serif !important;
}

.surveyor_vessels {
  font-family: "Roboto", sans-serif !important;
}

.verifier_surveylist {
  font-family: "Roboto", sans-serif !important;
}

.org_detail_page {
  font-family: "Roboto", sans-serif !important;
}

.CustomerAdminDashboard {
  font-family: "Roboto", sans-serif !important;
}

.conduct_survey {
  font-family: "Roboto", sans-serif !important;
}

.auth_form {
  font-family: "Roboto", sans-serif !important;
}

.modal form input::placeholder {
  font-size: 16px !important;
}

.modal form select::placeholder {
  font-size: 16px !important;
}

.cursor_pointer {
  cursor: pointer !important;
}

.cursor_banned {
  cursor: not-allowed !important;
}

.Reset_Password .OTP input {
  height: 35px;
  max-width: 250px;
  margin: 0rem 0.6rem 0rem 0.6rem;
}

#forgetPassword {
  color: #155497;
}

#forgetPassword:hover {
  text-decoration: underline;
}

.vdi_checklist img {
  vertical-align: top !important;
}

#Checklist_section_table tbody tr:hover {
  background: #e0e0e0;
}

#video_call_btn:hover {
  background: black !important;
}

#video_call_btn {
  background: #1c1c1c !important;
}

#CallOffBtn {
  background: #ff3131 !important;
}

#micOnBtn {
  background: #ffffff !important;
}

#micOffBtn {
  background: #079beb !important;
}

#videoOnBtn {
  background: #ffffff !important;
}

#videoOffBtn {
  background: #079beb !important;
}

#answerModalRow .card .card-header {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

#answerModalRow .card button {
  text-decoration: none !important;
}

.surveyor_vessels .card .card-body .row .card:hover {
  background: #2264ab;
  transition: ease-in;
}

.site_coordinator .card .card-body .row .card:hover {
  background: #2264ab;
  transition: ease-in;
}

.htmltemplate table {
  table-layout: fixed;
  width: 100%;
  border: 1px solid black;
}

.htmltemplate table th {
  text-align: left;
  padding: 3px;
  padding-bottom: 0;
}

.htmltemplate table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px;
  padding-bottom: 0;
}

.htmltemplate .gallery {
  display: flex;
  flex-direction: row;
}

.htmltemplate .thumbnail {
  flex: 0 0 50px;
  height: 50px;
  border: solid gray 3px;
}

.htmltemplate #thumbs {
  width: 100%;
  overflow: auto;
}

.htmltemplate ul {
  width: 1000px;
  list-style: none;
}

.htmltemplate li {
  float: left;
}

.htmltemplate .thumbnail-row {
  display: flex;
}

.htmltemplate .thumbnail-image {
  margin: 0px 10px 0px 10px;
  width: 10px;
  height: 20px;
  /* max-width: 100%;*/
}

.light_grey_border {
  border: 1px solid #dddcdb;
}

.rdt_TableCol {
  color: black;
  font-weight: bolder;
  font-size: 18px !important;
}

.page-wrapper .page-body-wrapper .page-sidebar {
  background: lightslategray !important;
}

#datePara {
  font-size: 10px !important;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, #000000b3, #000000b3, transparent);
  color: white !important;
  padding: 12px 12px 5px 12px;
}

.onHover:hover #imgOverlay {
  opacity: 1;
  transition: 0.5s;
}

#imgOverlay {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  inset: 0;
  margin: auto;
  background: #0000007d;
  opacity: 0;
  cursor: pointer;
}

#footerCompanyMarks {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0%, -50%);
}

.footer {
  font-family: "Roboto", sans-serif !important;
}

#attachmentbg {
  position: relative;
  border-radius: 2px;
  background: whitesmoke;
  border: 1px solid #dddcdb;
  padding: 10px 10px 5px 10px !important;
  list-style: none;
  display: inline-block;
}

#attachment_parent {
  position: relative !important;
}

@media screen and (max-width: 992px) {
  #inUseLogoInDesktopView {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .chat_screen {
    font-family: "Roboto", sans-serif !important;
    position: fixed;
    bottom: 80px;
    right: 50px;
    height: 80vh;
    max-width: 300px !important;
    background: whitesmoke;
    z-index: 999;
    border-radius: 8px;
  }
}

@media (min-width: 577px) and(max-width: 768px) {
  .chat_screen {
    font-family: "Roboto", sans-serif !important;
    position: fixed;
    bottom: 80px;
    right: 50px;
    height: 80vh;
    max-width: 400px !important;
    background: whitesmoke;
    z-index: 999;
    border-radius: 8px;
  }
}

.chat_screen {
  font-family: "Roboto", sans-serif !important;
  position: fixed;
  bottom: 80px;
  right: 50px;
  height: 80vh;
  width: 400px;
  background: whitesmoke;
  z-index: 999;
  border-radius: 8px;
  /* overflow: hidden !important; */
}

.chat_screen_inside {
  position: relative;
  /* background: red; */
  height: 80vh;
}

.chat_controls {
  width: 100%;
  background: white;
  position: absolute;
  bottom: 0px;
  border-radius: 8px;
}

.msg_list {
  width: 100%;
  position: absolute;
  bottom: 20px;
  overflow-x: auto;
  max-height: 75vh;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 40px;
  scroll-behavior: smooth;
}

#actualMsgInChat {}

#nameinchat {
  text-align: end;
}

/* 
.inputs {
  width: 100% !important;
  position: relative;
  bottom: 0px;
} */

#chatSendBtn {
  background: #2264ab;
  width: 60px;
  border-radius: 8px;
  border: none;
  outline: none;
  box-shadow: none;
}

#chatSendBtn svg {
  vertical-align: middle !important;
}

#shareFileChatBtn svg {
  vertical-align: middle !important;
}

#shareFileChatBtn {
  background: #3f403e;
  width: 60px;
  border-radius: 8px;
  border: none;
  outline: none;
  box-shadow: none;
}

#redDot {
  height: 10px;
  width: 10px;
  background: red;
  border-radius: 50%;
}

@media (min-width: 756px) {
  #auth_form_card {
    position: absolute !important;
    background: white !important;
    border-radius: 8px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}

@media (max-width: 576px) {
  #auth_form_card {
    position: relative !important;
    margin-top: 20px;
  }
}

.forgot_password:hover {
  text-decoration: underline;
}

.image_attachments {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  /* padding: 20px; */
  border-radius: 20px;
  text-align: center;
  height: 100%;
  cursor: pointer;
}

.video_overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 0;
  font-size: 20px;
  /* padding: 20px; */
  border-radius: 20px;
  text-align: center;
  height: 100%;
  cursor: pointer;
}

.date_overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 12px;
  padding: 5px 0px 5px 0px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}

.image_attachments:hover .overlay {
  opacity: 1;
}

.image_attachments:hover .video_overlay {
  opacity: 1;
}

.photo_attachments svg {
  vertical-align: middle !important;
}

.video_attachments svg {
  vertical-align: middle !important;
}

.bg-vedam {
  background-color: #2264ab !important;
  color: #fff !important;
}

.btn-vedam {
  background-color: #2264ab !important;
  border-color: #2264ab !important;
}

.references #reference_img {
  border-radius: 20px !important;
}

#surveyimg {
  border-radius: 20px !important;
}

#onlySurveyImg {
  border-radius: 20px !important;
}

#OnlySurveyDocs {
  border-radius: 20px !important;
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
}

.custom-file-upload:hover {
  background-color: #0069d9;
}

.form_columns {
  position: relative;
}

.form_columns span {
  height: 50px !important;
  background: grey !important;
  border-radius: 6px !important;
  padding: 10px 40px !important;
  margin: 5px !important;
  color: white;
}

.formTable table.table-bordered {
  border: 1px solid #bfbdbd;
  margin-top: 20px;
}

.formTable table.table-bordered>thead>tr>th {
  border: 1px solid #bfbdbd;
}

.formTable table.table-bordered>tbody>tr>td {
  border: 1px solid #bfbdbd;
}

.form-control-table {
  border: none;
}

.form-control-table:focus {
  outline: none;
  box-shadow: none;
}


ul,
#myUL {
  list-style-type: none;
}

#myUL {
  margin: 0;
  padding: 0;
}

.caret {
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.caret-down::before {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  /* Safari */
  transform: rotate(90deg);
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}


.caret2:before,
.caret2:after {
  display: inline-block;
  font-weight: bold;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.caret2::before {
  content: '+';
  display: inline-block;
  margin-right: 5px;

}

.caret2-down::before {
  content: '-';
  display: inline-block;
  margin-left: 5px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.nested {
  display: none;
}

.active {
  display: block;
}

.my-nav-pills .nav-link.active,
.my-nav-pills .show>.nav-link {
  background-color: #2264ab !important;
  color: white !important;
}

.my-nav-pills .nav-link,
.my-nav-pills .show>.nav-link {
  background-color: #d1cfcf !important;
  color: black !important;
  margin-right: 5px;
}

.my-nav-pills .nav-link {
  color: black !important
}

#myUL li:focus {
  background: white;
  border-radius: 8px;
  border: 1px solid rgb(236, 236, 236);
  margin-bottom: 4px;
  color: black;
  font-weight: '600';
}


.toogle_switch .switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.toogle_switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toogle_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.toogle_switch .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.toogle_switch input:checked+.slider {
  background-color: #2264AB;
}

.toogle_switch input:focus+.slider {
  box-shadow: 0 0 1px #2264AB;
}

.toogle_switch input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}


/* Rounded sliders */
.toogle_switch .slider.round {
  border-radius: 17px;
}

.toogle_switch .slider.round:before {
  border-radius: 50%;
}



.filter .dropdown-basic .dropdown .dropdown-content {
  width: 300px !important;
  min-height: 200px !important;
  /* text-overflow: ellipsis !important;
  display: inline-block !important;
  white-space: nowrap; */
}

.filter-dropdown .dropdown button {
  border-radius: 20px !important;
}







.btn-primary-blue:focus {
  box-shadow: 49, 132, 253;
  border-color: #2264ab !important;
  background: #2264ab !important;
  color: white !important;
}

.btn-primary-blue:active {
  color: white !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  border-color: #2264ab !important;
  background: #2264ab !important;
}

.btn-primary-blue:disabled {
  color: white !important;
  border-color: #2264ab;
  background: #2264ab;
}

.bg-primary-blue {
  background: #2264ab !important;
  color: white !important;
}

.bg-primary-blue:hover {
  background: #2264ab !important;
  color: white !important;
}


#mytable table {
  width: 100%;
  display: grid;
  overflow: scroll;
  grid-template-columns:
    minmax(150px, 3fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr);
}

#mytable thead,
#mytable tbody,
#mytable tr {
  display: contents;
}

#mytable th {
  position: relative;
  font-size: 18px;
}

#mytable th,
#mytable td {
  text-align: left;
  padding: 16px 20px;
  min-width: 100px;
  border: 1px solid #c6c6c6;
}

#mytable span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

#mytable table tr td {
  border-top: 1px solid #ccc;
}

#mytable .resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;
}

#mytable .resize-handle:hover {
  border-color: #ccc;
}

#mytable .resize-handle.active {
  border-color: #517ea5;
}


.filter-dropdown .dropdown-menu {
  padding: 15px 15px 15px 15px !important;
  width: 260px !important;
}
.pill-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  /* Adjust the gap between pills as needed */
}

.custom-pill {
  background-color: #2264ab;
  color: white !important;
  border-radius: 2px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 15px;
  /* Adjust the margin between pills as needed */
  cursor: pointer;
  user-select: none;
}

